(function($){
  var app = window.app || {};

  app.initCarousels = function(){
    if (! app.utils.isTouchCapable()) {
      var $carousels = $('[data-carousel]').not('.slick-initialized');
      $carousels.slick({
        respondTo: 'min',
        speed: 250,
        infinite: false,
        mobileFirst: true,
        swipeToSlide: false,
        variableWidth: true,
        slidesToScroll: 1,
        slidesToShow: 1,
        waitForAnimate: false,
        responsive:[
          {
            breakpoint:450,
            settings: {
              slidesToScroll: 2,
              slidesToShow: 2,
            }
          },
          {
            breakpoint:670,
            settings: {
              slidesToScroll: 3,
              slidesToShow: 3
            }
          },
          {
            breakpoint:910,
            settings: {
              slidesToScroll:4,
              slidesToShow: 4
            }
          },
          {
            breakpoint:1110,
            settings: {
              slidesToScroll:5,
              slidesToShow: 5
            }
          },
          {
            breakpoint:1330,
            settings: {
              slidesToScroll:6,
              slidesToShow: 6
            }
          },
          {
            breakpoint:1550,
            settings: {
              slidesToScroll:7,
              slidesToShow: 7
            }
          },
          {
            breakpoint:1770,
            settings: {
              slidesToScroll:8,
              slidesToShow: 8
            }
          },
          {
            breakpoint:1940,
            settings: {
              slidesToScroll:9,
              slidesToShow: 9
            }
          }
        ]
      });
    }
  };

}(jQuery));
